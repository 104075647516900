import { SvgIcon } from '@mui/material'

const SelectIcon = ({ sx = {}, ...props }) => {
  return (
    <SvgIcon
      sx={{ fill: 'none !important', width: '10px', height: '6px', ...sx }}
      viewBox='0 0 10 6'
      fill='none'
      {...props}>
      <path
        d='M1 1L5 5L9 1'
        stroke='#999999'
        strokeWidth='1.33333'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
    </SvgIcon>
  )
}

export default SelectIcon
