import { Box, Typography } from '@mui/material'
import React, { useEffect, useRef, useState } from 'react'
import CustomCountriesSelect from './CustomCountriesSelect'
import CustomSchoolSelect from './CustomSchoolSelect'
import CustomButton from '../Button'
import Input from '../TextField'
import { StyledSchoolSnippet } from './styled'
import { useController, useForm } from 'react-hook-form'

import { countries } from './CustomCountriesSelect/countries'
import { SchoolService } from '../../services/School.service'
import CrossMarkIcon from '../../assets/icons/CrossmarkIcon'

const initialState = {
  country: '',
  name: '',
  city: '',
}

const CustomSchoolSnippet = () => {
  const [schools, setSchools] = useState([])
  const [isLoadingSchools, setIsLoadingSchools] = useState(false)
  const [isAddingMode, setIsAddingMode] = useState(false)
  const [currentCountry, setCurrentCountry] = useState('')
  const [isSubmitted, setIsSubmitted] = useState(true)
  const [isLoading, setIsLoading] = useState(false)
  const [isSubmitError, setIsSubmitError] = useState(false)

  const countryHidden = useRef(null)
  const schoolNameHidden = useRef(null)
  const cityHidden = useRef(null)
  const idHidden = useRef(null)
  const salesforceId = useRef(null)

  const ref = useRef(null)

  const {
    getValues,
    control,
    formState: { errors },
    setError,
  } = useForm({ mode: 'onChange', defaultValues: initialState })

  const { field: country } = useController({
    name: 'country',
    control: control,
    rules: {
      required: { value: true, message: 'This field is required' },
      validate: (value) => {
        if (!countries.find(({ label }) => label.toLowerCase() === value.toLowerCase()))
          return 'Enter a valid country name'
      },
    },
  })

  const { field: name } = useController({
    name: 'name',
    control: control,
    rules: {
      required: { value: true, message: 'This field is required' },
      minLength: { value: 2, message: 'Enter a valid school name' },
      validate: (value) => {
        if (!isAddingMode) {
          if (!schools.find(({ name }) => name.toLowerCase() === value.toLowerCase()))
            return 'Enter a valid school name'
        }
      },
    },
  })

  const { field: city } = useController({
    name: 'city',
    control: control,
    rules: {
      required: { value: true, message: 'This field is required' },
      minLength: { value: 2, message: 'Enter a valid school name' },
    },
  })

  const onSubmit = async () => {
    const data = getValues()

    const callbackSuccessful = (school) => {
      setIsLoading(false)
      setIsSubmitted(true)

      if (idHidden.current) idHidden.current.value = school.id
      if (schoolNameHidden.current) schoolNameHidden.current.value = school.name
      if (cityHidden.current) cityHidden.current.value = school.city
      if (salesforceId.current) salesforceId.current.value = school.salesforceId
    }

    const callbackRejected = () => {
      setIsLoading(false)
      setIsSubmitted(false)
      setIsSubmitError(true)
    }

    if (isAddingMode) {
      setIsLoading(true)
      try {
        const school = await SchoolService.postSchool(
          {
            ...data,
            name: data.name.split(',')[0].trim(),
          },
          callbackSuccessful,
          callbackRejected,
        )
      } catch (error) {}
    }
  }

  const onClickNoItemsButton = (state) => {
    setIsAddingMode(state)
    name.onChange('')
    city.onChange('')
  }

  const onChangeCountry = (countryName) => {
    if (country.value !== countryName) {
      country.onChange(countryName)
      city.onChange('')

      if (name.value) {
        name.onChange('')
      }

      setIsAddingMode(false)
    }
  }

  const onChangeSchoolName = (school) => {
    name.onChange(school.name.replace('’', "'"))
    idHidden.current.value = school?.id
    cityHidden.current.value = school.city
    salesforceId.current.value = school.salesforceId
  }

  const onFocusSchoolInput = async () => {
    if (country.value && currentCountry !== country.value) {
      setCurrentCountry(country.value)
      setIsLoadingSchools(true)

      try {
        const schoolsByCountry = await SchoolService.getSchoolsByCountry(
          country.value.toLowerCase(),
        )
        setIsLoadingSchools(false)

        setSchools(
          schoolsByCountry
            .map((school) => ({
              ...school,
              name: `${school.name}${school.city ? `, ${school.city}` : ''}`,
            }))
            .sort((a, b) => a.name.localeCompare(b.name)),
        )
      } catch (error) {
        setSchools([])
        setIsLoadingSchools(false)
      }
    }
  }

  const onClickUndo = () => {
    setIsSubmitted(false)
    setIsAddingMode(false)
    name.onChange('')
    city.onChange('')
  }

  const showButton =
    isAddingMode && !errors?.name?.message && !errors?.country?.message && !errors?.city?.message

  useEffect(() => {
    setIsSubmitted(false)
    setIsSubmitError(false)
  }, [name.value, country.value, city.value])

  useEffect(() => {
    if (ref.current) {
      const parentForm = ref.current?.form

      if (parentForm) {
        const schoolCountryInputHidden = parentForm.querySelector('.immerse-school-country input')
        const schoolNameInputHidden = parentForm.querySelector('.immerse-school-name input')
        const schoolCityInputHidden = parentForm.querySelector('.immerse-school-city input')
        const schoolIdInputHidden = parentForm.querySelector('.immerse-school-id input')
        const schoolSalesforceIdHidden = parentForm.querySelector(
          '.immerse-school-salesforce-id input',
        )

        const fetchSchools = async () => {
          setIsLoadingSchools(true)
          const schoolsByCountry = await SchoolService.getSchoolsByCountry(
            schoolCountryInputHidden.dataset.set.toLowerCase(),
          )
          setIsLoadingSchools(false)

          setSchools(
            schoolsByCountry
              .map((school) => ({
                ...school,
                name: `${school.name}${school.city ? `, ${school.city}` : ''}`,
              }))
              .sort((a, b) => a.name.localeCompare(b.name)),
          )

          name.onChange(schoolNameInputHidden.dataset.set || '')
        }

        if (!schools.length && schoolCountryInputHidden.dataset.set) {
          fetchSchools()
        }

        if (
          schoolCountryInputHidden.dataset?.set ||
          schoolNameInputHidden.dataset?.set ||
          schoolCityInputHidden.dataset?.set
        ) {
          country.onChange(schoolCountryInputHidden.dataset.set || '')
          city.onChange(schoolCityInputHidden.dataset.set || '')
          name.onChange(schoolNameInputHidden.dataset.set || '')
        }

        countryHidden.current = schoolCountryInputHidden
        schoolNameHidden.current = schoolNameInputHidden
        cityHidden.current = schoolCityInputHidden
        idHidden.current = schoolIdInputHidden
        salesforceId.current = schoolSalesforceIdHidden
      }
    }
  }, [ref.current])

  useEffect(() => {
    if (countryHidden.current) countryHidden.current.value = country.value
  }, [country.value])

  useEffect(() => {
    if (schoolNameHidden.current) schoolNameHidden.current.value = name.value
  }, [name.value])

  useEffect(() => {
    if (cityHidden.current) cityHidden.current.value = city.value
  }, [city.value])

  return (
    <StyledSchoolSnippet className='school-snippet-container'>
      <input ref={ref} style={{ display: 'none' }} />
      {!isSubmitted ? (
        <div className='form'>
          <CustomCountriesSelect
            value={country.value}
            onChange={onChangeCountry}
            error={errors?.country?.message}
            name='schoolCountry'
            placeholder='Select country'
            label='Country *'
          />
          <CustomSchoolSelect
            schools={schools}
            value={!isAddingMode ? name.value : ''}
            onChange={(v) => name.onChange(v.replace('’', "'"))}
            onChangeSchoolName={onChangeSchoolName}
            onClickNoItemsButton={() => onClickNoItemsButton(true)}
            onFocusSchoolInput={onFocusSchoolInput}
            error={!isAddingMode ? errors?.name?.message : ''}
            isLoading={isLoadingSchools}
            isCountrySelected={!!country.value}
            placeholder='Search for your school'
            label='School Name *'
            name='schoolName'
          />

          {isAddingMode && (
            <Box className='add-from' sx={{ marginBottom: '16px' }}>
              <Box className='add-form-header'>
                <Typography className='add-form-header-title' component='h3'>
                  Add school
                </Typography>
                <CustomButton
                  onClick={() => onClickNoItemsButton(false)}
                  className='add-form-delete'
                  isLight>
                  Delete
                </CustomButton>
              </Box>

              <Box className='form'>
                <Box className='form-col'>
                  <Input
                    value={name.value}
                    onChange={name.onChange}
                    error={errors?.name?.message}
                    containerClass='form-item'
                    label='School name'
                    placeholder='Enter school name'
                    name='schoolName'
                  />

                  <Input
                    error={errors?.city?.message}
                    value={city.value}
                    onChange={city.onChange}
                    containerClass='form-item'
                    label='City'
                    placeholder='Enter city'
                    name='schoolCity'
                  />
                </Box>
              </Box>
            </Box>
          )}
          {showButton && (
            <CustomButton
              containerClass='form-submit-button'
              disabled={
                !!errors?.name?.message ||
                !!errors?.city?.message ||
                isLoading ||
                isSubmitted ||
                isSubmitError
              }
              isError={isSubmitError}
              isLoading={isLoading}
              onClick={onSubmit}>
              {isSubmitError ? 'Failed to add' : 'Add school'}
            </CustomButton>
          )}
        </div>
      ) : (
        <div className='school-block'>
          <button onClick={onClickUndo} className='school-block-undo-button'>
            <CrossMarkIcon />
          </button>
          <Typography className='school-block-text'>{`${name.value}, ${city.value}`}</Typography>
        </div>
      )}
    </StyledSchoolSnippet>
  )
}

export default CustomSchoolSnippet
