import { Box } from '@mui/material'
import styled from 'styled-components'

export const CountryFieldWrapper = styled(Box)`
  position: relative;
  width: 376px;
  margin-bottom: 24px;

  @media (max-width: 768px) {
    width: 100%;
  }

  .loader-container {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 30px 32px 33px;
  }

  .loader {
    width: 36px;
    height: 36px;
    border: 3px solid #181c2a;
    border-bottom-color: transparent;
    border-radius: 50%;
    display: inline-block;
    box-sizing: border-box;
    animation: rotation 1s linear infinite;
  }

  @keyframes rotation {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }

  .text-field {
    svg {
      transition: transform 0.1s ease-in-out;
    }
  }

  .focused {
    svg {
      transform: rotate(-180deg);
    }
  }

  .country-flag {
    width: 24px;
  }

  .select-input {
    .MuiInputBase-root {
      padding-left: 16px !important;
      padding-right: 16px !important;
    }

    .MuiInputBase-input {
      padding-left: 0px !important;
    }
  }

  .selected {
    .MuiInputBase-input {
      padding-left: 12px !important;
    }
  }

  .no-items {
    padding: 16px 0;
    font-weight: 450;
    font-size: 16px;
    line-height: 20px;
    color: #1d1d1d;
    text-align: center;
  }

  .country-dropdown {
    margin-top: 4px;
    min-width: 100%;
    min-height: 30px;
    display: flex;
    flex-direction: column;
    background: #fff;
    position: absolute;
    top: 100%;
    z-index: 10;
    max-height: 300px;
    overflow-y: auto;
    padding: 8px;
    gap: 10px;
    border: 1px solid #c0c8cf;
    border-radius: 8px;

    .country {
      display: flex;
      align-items: center;
      gap: 12px;
      padding: 8px;
      cursor: pointer;
      font-weight: 450;
      font-size: 16px;
      line-height: 20px;
      color: #1d1d1d;
      border-radius: 4px;
      transition: all 0.2s ease-in-out;

      &-selected {
        background: #e9ecef;
        color: #2d4960;
      }

      &:hover {
        background: #e9ecef;
        color: #2d4960;
      }

      &-flag {
        width: 24px;
      }
    }
  }

  *::-webkit-scrollbar {
    width: 3px;
  }

  *::-webkit-scrollbar-track {
    background: transparent;
  }

  *::-webkit-scrollbar-thumb {
    border-radius: 100px;
    height: 64px;
    border: 3px solid #d9d9d9;
  }
`
