import { Box } from '@mui/material'
import styled from 'styled-components'

export const SchoolFieldWrapper = styled(Box)`
  position: relative;
  width: 376px;
  margin-bottom: 24px;

  @media (max-width: 768px) {
    width: 100%;
  }

  .loader-container {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 30px 32px 33px;
  }

  .loader {
    width: 36px;
    height: 36px;
    border: 3px solid #181c2a;
    border-bottom-color: transparent;
    border-radius: 50%;
    display: inline-block;
    box-sizing: border-box;
    animation: rotation 1s linear infinite;
  }

  @keyframes rotation {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }

  .no-items {
    padding: 30px 32px 33px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    @media (max-width: 768px) {
      padding: 24px;
    }

    &-text {
      text-align: center;
      font-weight: 450;
      font-size: 14px;
      line-height: 20px;
      color: #6f6f6f;
      margin-bottom: 16px;
    }

    &-button {
      width: 100%;
      padding: 10px 0px;
    }

    &-button-container {
      width: 100%;
    }
  }

  .text-field {
    .Mui-disabled {
      background: #f5f5f7;
      border-color: #c9c9c9;
      color: #c9c9c9;
    }
  }

  .school-dropdown {
    margin-top: 4px;
    min-width: 100%;
    min-height: 30px;
    display: flex;
    flex-direction: column;
    background: #fff;
    position: absolute;
    top: 100%;
    z-index: 10;
    max-height: 500px;
    overflow-y: auto;
    padding: 8px;
    gap: 6px;
    border: 1px solid #c0c8cf;
    border-radius: 8px;

    .school {
      display: flex;
      align-items: center;
      gap: 4px;
      padding: 8px;
      cursor: pointer;
      font-weight: 450;
      font-size: 16px;
      line-height: 20px;
      color: #1d1d1d;
      border-radius: 4px;
      transition: all 0.2s ease-in-out;

      &-selected {
        background: #e9ecef;
        color: #2d4960;
      }

      &:hover {
        background: #e9ecef;
        color: #2d4960;
      }
    }
  }

  *::-webkit-scrollbar {
    width: 3px;
  }

  *::-webkit-scrollbar-track {
    background: transparent;
  }

  *::-webkit-scrollbar-thumb {
    border-radius: 100px;
    height: 64px;
    border: 3px solid #d9d9d9;
  }

  .search-icon {
    path {
      fill: #c9c9c9;
    }
  }

  .Mui-focused {
    .search-icon {
      path {
        fill: #181c2a;
      }
    }
  }
`
