import { useEffect, useRef, useState } from 'react'
import { Box, Typography } from '@mui/material'
import cn from 'classnames'

import { CountryFieldWrapper } from './styled'
import Input from '../../TextField'
import { useOnClickOutside } from '../../../hooks/useOutsideClick'
import SelectIcon from '../../../assets/icons/SelectIcon'

import { countries } from './countries'

const getSearchedCountry = (arr, searchTerm) =>
  arr.filter(({ label }) => label.toLowerCase().includes(searchTerm.toLowerCase()))

const CustomCountriesSelect = ({
  containerClass,
  value,
  onChange,
  label,
  placeholder,
  error,
  isLoading,
  name,
}) => {
  const [inputValue, setInputValue] = useState(
    value
      ? {
          value: value,
          flag: countries.find((country) => value === country.label)
            ? countries.find((country) => value === country.label).code
            : '',
        }
      : {
          value: '',
          flag: '',
        },
  )
  const [isVisible, setIsVisible] = useState(false)

  const ref = useRef(null)

  const onClickCountry = (title, flag) => {
    setInputValue({ value: title, flag: flag })
    setIsVisible(false)
    onChange(title)
  }

  const onChangeCountry = (value, flag) => {
    setInputValue({ value, flag })
    onChange(value)
  }

  useOnClickOutside(ref, () => setIsVisible(false))

  useEffect(() => {
    if (ref.current) {
      ref.current.querySelector('input').addEventListener('click', () => {
        setIsVisible(true)
      })
    }
  }, [ref])

  return (
    <CountryFieldWrapper ref={ref} className={containerClass}>
      <Input
        value={inputValue.value}
        onChange={(e) => onChangeCountry(e.target.value)}
        onClick={() => setIsVisible(true)}
        onTouchStart={() => setIsVisible(true)}
        onFocus={() => setIsVisible(true)}
        placeholder={placeholder}
        autoComplete='off'
        className={cn('select-input', {
          focused: isVisible,
          selected: inputValue.flag,
        })}
        type='text'
        label={label}
        error={error}
        name={name}
        InputProps={{
          endAdornment: <SelectIcon />,
          startAdornment: inputValue.flag && (
            <img
              alt={inputValue.value + ' flag'}
              srcSet={`https://flagcdn.com/w40/${inputValue.flag.toLowerCase()}.png 2x`}
              src={`https://flagcdn.com/w20/${inputValue.flag.toLowerCase()}.png`}
              className='country-flag'
            />
          ),
        }}
      />
      {isVisible && (
        <Box className='country-dropdown'>
          {!isLoading ? (
            <>
              {getSearchedCountry(countries, inputValue.value).length ? (
                getSearchedCountry(countries, inputValue.value).map((country) => (
                  <div
                    key={country.label}
                    className={cn('country', {
                      'country-selected': country.label === inputValue,
                    })}
                    onClick={() => onClickCountry(country.label, country.code)}>
                    <img
                      alt={country.label + ' flag'}
                      srcSet={`https://flagcdn.com/w40/${country.code.toLowerCase()}.png 2x`}
                      src={`https://flagcdn.com/w20/${country.code.toLowerCase()}.png`}
                      className='country-flag'
                    />
                    {country.label}
                  </div>
                ))
              ) : (
                <Typography className='no-items'>No matched countries</Typography>
              )}
            </>
          ) : (
            <div className='loader-container'>
              <div className='loader'></div>
            </div>
          )}
        </Box>
      )}
    </CountryFieldWrapper>
  )
}

export default CustomCountriesSelect
