import axios from 'axios'

const axiosClassic = axios.create({
  baseURL: 'https://api.enrol.immerse.education',
  headers: {
    'Content-Type': 'application/json',
  },
})

const getLSEnrolmentData = () => {
  return JSON.parse(localStorage.getItem('enrolmentOrderData'))
}

const setLSEnrolmentData = (data) => {
  localStorage.setItem('enrolmentOrderData', JSON.stringify(data))
}

export const SchoolService = {
  async getSchoolsByCountry(country) {
    try {
      const res = await axiosClassic.get(`/school/country/${country}`)
      return res.data
    } catch (error) {}
  },

  async postSchool(body, callbackSuccessful, callbackRejected) {
    try {
      const { data: school } = await axiosClassic.post(`/school`, body)

      callbackSuccessful(school)

      return school
    } catch (error) {
      callbackRejected()
    }
  },

  async updateEnrolmentData(body) {
    try {
      const enrolment = getLSEnrolmentData()
      const res = await axiosClassic.put(`/order/${enrolment.id}`, {
        ...enrolment,
        ...body,
      })

      setLSEnrolmentData(res.data)
    } catch (error) {}
  },
}
