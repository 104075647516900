import React from 'react'
import ReactDOM from 'react-dom/client'
import { CssBaseline } from '@mui/material'

import CustomSchoolSnippet from './components/CustomSchoolSnippet'

import { theme } from './theme'

import './index.css'
import { ThemeProvider } from 'styled-components'

document.querySelectorAll('#root').forEach((rootElem) => {
  if (!rootElem.childNodes.length) {
    ReactDOM.createRoot(rootElem).render(
      <React.StrictMode>
        <ThemeProvider theme={theme}>
          <CustomSchoolSnippet />
          <CssBaseline />
        </ThemeProvider>
      </React.StrictMode>,
    )
  }
})

// root.render(
//   <React.StrictMode>
//     <ThemeProvider theme={theme}>
//       <CustomSchoolSnippet />
//       <CssBaseline />
//     </ThemeProvider>
//   </React.StrictMode>,
// )
