import { useRef, useState } from 'react'
import { Box, Typography } from '@mui/material'
import cn from 'classnames'

import { SchoolFieldWrapper } from './styled'
import Input from '../../TextField'
import { useOnClickOutside } from '../../../hooks/useOutsideClick'
import CustomButton from '../../Button'
import SearchIcon from '../../../assets/icons/SearchIcon'

// const cityHidden = document.querySelector('.immerse-school-city input')
// const idHidden = document.querySelector('.immerse-school-id input')

const getSearchedSchool = (arr, searchTerm) =>
  arr.filter(({ name }) => name.toLowerCase().includes(searchTerm.toLowerCase()))

const CustomSchoolSelect = ({
  schools,
  containerClass,
  value,
  onChange,
  onFocusSchoolInput,
  label,
  placeholder,
  error,
  onClickNoItemsButton,
  disabled,
  isLoading,
  name,
  isCountrySelected,
  onChangeSchoolName,
}) => {
  const [inputValue, setInputValue] = useState(value || '')
  const [isVisible, setIsVisible] = useState(false)

  const ref = useRef(null)

  const onClickSchool = (title, id, city, salesforceId) => {
    setInputValue(title)
    setIsVisible(false)

    onChangeSchoolName({ city, id, name: title, salesforceId })

    // if (cityHidden) cityHidden.value = title.split(',')[title.split(',').length - 1]
    // if (idHidden) idHidden.value = id
  }

  const onChangeSchool = (value) => {
    if (isCountrySelected) {
      setInputValue(value)
      onChange(value)
    }
  }

  const onClickButton = () => {
    onClickNoItemsButton()
    setIsVisible(false)
    setInputValue('')
  }

  const onFocus = () => {
    setIsVisible(true)
    onFocusSchoolInput()
  }

  useOnClickOutside(ref, () => setIsVisible(false))

  return (
    <SchoolFieldWrapper ref={ref} className={containerClass}>
      <Input
        value={value}
        onChange={(e) => onChangeSchool(e.target.value)}
        onFocus={onFocus}
        placeholder={placeholder}
        autoComplete='off'
        type='text'
        label={label}
        error={error}
        disabled={disabled}
        name={name}
        InputProps={{
          endAdornment: <SearchIcon className='search-icon ' />,
        }}
      />
      {isVisible &&
        (isCountrySelected ? (
          value.length >= 2 && (
            <Box className='school-dropdown'>
              {!isLoading ? (
                <>
                  {getSearchedSchool(schools, value).length ? (
                    getSearchedSchool(schools, value)
                      .slice(0, 10)
                      .map(({ id, name, city, salesforceId }) => (
                        <div
                          key={id}
                          className={cn('school', {
                            'school-selected': name === value,
                          })}
                          onClick={() => onClickSchool(name, id, city, salesforceId)}>
                          {name}
                        </div>
                      ))
                  ) : (
                    <Box className='no-items'>
                      <Typography className='no-items-text'>
                        We can't find any results in our database. Please add your school manually
                      </Typography>
                      <CustomButton
                        className='no-items-button'
                        containerClass='no-items-button-container'
                        onClick={onClickButton}>
                        Add another school
                      </CustomButton>
                    </Box>
                  )}
                </>
              ) : (
                <div className='loader-container'>
                  <div className='loader'></div>
                </div>
              )}
            </Box>
          )
        ) : (
          <Box className='school-dropdown'>
            <Typography sx={{ fontSize: '16px', lineHeight: '24px', padding: '4px 8px' }}>
              Please select a country first
            </Typography>
          </Box>
        ))}
    </SchoolFieldWrapper>
  )
}

export default CustomSchoolSelect
