import { Button, CircularProgress } from '@mui/material'
import cn from 'classnames'
import { StyledButtonContainer } from './styled'

const CustomButton = ({
  isLight,
  children,
  className,
  isLoading,
  containerClass = '',
  isError,
  ...props
}) => {
  return (
    <StyledButtonContainer className={containerClass}>
      <Button
        {...props}
        className={cn(className, { 'button-light': isLight, 'button-error': isError })}>
        {isLoading ? <CircularProgress size={24} color='text' /> : children}
      </Button>
    </StyledButtonContainer>
  )
}

export default CustomButton
