import { Box, InputLabel, TextField } from '@mui/material'
import cn from 'classnames'
import styled from 'styled-components'

const StyledTextField = styled(Box)`
  .text-field {
    width: 100%;

    &-label {
      ${({ theme }) => theme.typography.textSecondary};
      white-space: initial;
      margin-bottom: 0.8rem;

      &-error {
        color: #c8102e;
      }
    }

    &-error {
      ${({ theme }) => theme.typography.textSmall};
      color: #c8102e;
    }
  }

  .text-field-border-error {
    border-color: #c8102e;
  }
`

const Input = ({
  value,
  onChange,
  label,
  placeholder,
  error,
  containerClass = '',
  className,
  id,
  ...props
}) => {
  return (
    <StyledTextField className={containerClass}>
      {label && (
        <InputLabel
          className={cn('text-field-label', {
            'text-field-label-error': error,
          })}
          shrink={false}
          htmlFor={id}>
          {label}
        </InputLabel>
      )}
      <TextField
        id={id}
        value={value}
        onChange={onChange}
        placeholder={placeholder}
        error={error}
        className={cn('text-field', {
          [className]: className,
          'text-field-error': error,
        })}
        {...props}
      />
      {error && <Box className='text-field-error'>{error}</Box>}
    </StyledTextField>
  )
}

export default Input
