import { Box } from '@mui/material'
import styled from 'styled-components'

export const StyledButtonContainer = styled(Box)`
  .button-light {
    color: ${({ theme }) => theme.palette.text.black};
    background-color: ${({ theme }) => theme.palette.main.lightGray};

    & > svg {
      path {
        stroke: ${({ theme }) => theme.palette.text.black};
        fill: ${({ theme }) => theme.palette.text.black};
      }
    }

    &:hover {
      box-shadow: 0px 11px 22px rgba(0, 0, 0, 0.1);
      background-color: #2d4960;
      color: #ffffff;

      & > svg {
        path {
          stroke: ${({ theme }) => theme.palette.main.white};
          fill: ${({ theme }) => theme.palette.text.black};
        }
      }
    }
  }

  .button-error {
    background-color: #ec1e32;
    opacity: 1;
  }
`
